
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";
import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Factor, Choice, Tab, UserPermission } from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import Factors from "@/store/modules/Factors";
import Choices from "@/store/modules/Choices";
import Workspaces from "@/store/modules/Workspaces";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import CommentBase from "@/components/mixins/CommentBase";
import CommentChoices from "@/components/comments/tab/CommentChoices.vue";
import CommentSettings from "@/components/comments/tab/CommentSettings.vue";
import ComparePagination from "@/components/compare/ui/ComparePagination.vue";
import CompareColumnHeaders from "@/components/compare/ui/CompareColumnHeaders.vue";
import CommentUserRecursive from "@/components/comments/userTab/CommentUserRecursive.vue";

const decisionsModule = getModule(Decisions);
const modelModule = getModule(Factors);
const choiceModule = getModule(Choices);
const workspaceModule = getModule(Workspaces);

@Component({
    components: {
        ViewWrapper,
        CommentChoices,
        CommentSettings,
        ComparePagination,
        CompareColumnHeaders,
        CommentUserRecursive,
    },
})
export default class CommentsUsersView extends mixins(CommentBase) {
    private darkBorder = true;
    private usersLoading = false;
    private allUsers: UserPermission[] = [];

    private selectedChoice: Choice | null = null;

    get pageTitle(): string {
        return "Comments | Users";
    }

    get workspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    get choiceLabelPlural(): string {
        return decisionsModule.choiceLabelPlural;
    }

    get choicesLabel(): string {
        return decisionsModule.choiceLabelPlural;
    }

    get choiceLabel(): string {
        return decisionsModule.choiceLabelSingular;
    }

    /* Compare Values can be sorted here */
    get compareChoices(): Choice[] {
        return this.choices;
    }

    get compareUsers(): UserPermission[] {
        return this.allUsers;
    }

    get columnPages(): number {
        if (!this.pagination.columns.all && this.pagination.columns.items > 0) {
            return this.compareChoices.length - this.pagination.columns.items;
        } else {
            return 0;
        }
    }

    get paginationUsers(): UserPermission[] {
        if (!this.pagination.columns.all) {
            return this.compareUsers.slice(
                this.pagination.columns.position,
                this.pagination.columns.position + this.pagination.columns.items
            );
        } else {
            return this.compareUsers;
        }
    }

    get selectedChoiceId(): number | null {
        return this.selectedChoice ? this.selectedChoice.id : null;
    }

    private async saveSettings(): Promise<void> {
        console.log("Saving...");
    }

    private formatUser(user: UserPermission): string {
        if (user.user.first) {
            if (user.user.last) {
                return `${user.user.first} ${user.user.last}`;
            } else {
                return user.user.first;
            }
        } else if (user.user.email) {
            return user.user.email;
        } else {
            return "User";
        }
    }

    async mounted(): Promise<void> {
        this.onWatchWorkspaceId(this.workspaceId);
    }

    @Watch("selectedChoiceId")
    async onChoiceChange(newVal: number | null): Promise<void> {
        if (newVal) {
            await choiceModule.getChoiceValues({
                choice_id: newVal,
                root_only: true,
            });
        }
    }

    @Watch("workspaceId")
    async onWatchWorkspaceId(newVal: number | null): Promise<void> {
        if (newVal) {
            this.usersLoading = true;
            this.allUsers = await GRAPHQL_API.getWorkspaceUsers(newVal);
            this.usersLoading = false;
        }
    }
}
