
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Factors from "@/store/modules/Factors";
import Scoring from "@/store/modules/Scoring";
import {
    DefaultClassSets,
    Factor,
    FactorType,
    FactorCreateInputL,
    Unit,
    Enum,
    Task,
    DecisionDefaults,
} from "@/graphql/API";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import Decisions from "@/store/modules/Decisions";
import StyleEditor from "@/components/common/StyleEditor.vue";
import {
    createFactorL,
    updateFactorL,
    getNextOrderStrs,
} from "@/helpers/FactorHelper";
import Domains from "@/store/modules/Domains";
import FactorScoringOptions from "@/components/model/FactorScoringOptions.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import FactorScoreRule from "@/components/model/FactorScoreRule.vue";
import RichTextEditor from "@/components/ui/RichTextEditor.vue";
import FactorPdfUploader from "@/components/ui/FactorPdfUploader.vue";
import FlashNotifications from "@/store/modules/FlashNotifications";
import _ from "lodash";
import { FactorsIcons } from "@/IconLibrary";
import HTMLEditor from "@/components/ui/texteditor/HTMLEditor.vue";

const modelModule = getModule(Factors);
const decisionModule = getModule(Decisions);
const flashNotificationsModule = getModule(FlashNotifications);
const scoringModule = getModule(Scoring);
@Component({
    components: {
        DialogCardTitle,
        StyleEditor,
        FactorScoringOptions,
        VpDialog,
        FactorScoreRule,
        RichTextEditor,
        FactorPdfUploader,
        HTMLEditor,
    },
})
export default class FactorCreator extends Vue {
    @Prop({ type: Number })
    parentId!: number;

    @Prop({
        default: (factor: Factor) => {
            return factor;
        },
        type: Object,
    })
    factorEdit!: Factor;

    @Prop({ default: "", type: String })
    ordering!: string;

    @Prop({ default: "mdi-plus", type: String })
    icon!: string;

    @Prop({ default: "", type: String })
    btnText!: string;

    @Prop({ default: false, type: Boolean })
    isEdit!: boolean;

    @Prop({ default: false, type: Boolean })
    isColumn!: boolean;

    @Prop({ default: false, type: Boolean })
    isRow!: boolean;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    @Prop({ default: false, type: Boolean })
    isPdf!: boolean;

    @Prop({ default: false, type: Boolean })
    addOnly!: boolean;

    private actionsMenu = false;
    private readonly factorsIcons = FactorsIcons;
    private styleClasses = [
        {
            name: "Heading 1",
            value: "vp-heading-1",
        },
        {
            name: "Heading 2",
            value: "vp-heading-2",
        },
        {
            name: "Heading 3",
            value: "vp-heading-3",
        },
        {
            name: "Heading 4",
            value: "vp-heading-4",
        },
        {
            name: "Heading 5",
            value: "vp-heading-5",
        },
        {
            name: "Body 1",
            value: "vp-body-1",
        },
        {
            name: "Body 2",
            value: "vp-body-2",
        },
        {
            name: "Caption",
            value: "vp-caption",
        },
    ];

    private selectedFactorStyleName = {
        name: "Body 1",
        value: "vp-body-1",
    };

    private blankFactor: Factor = {
        id: 0,
        name: "",
        description: "",
        parent_id: -1,
        type: "",
        order_str: "",
        value_type: "",
        json: "",
        is_table: false,
        is_group: false,
        is_column: false,
        format: "",
        m_score: 0,
        m_index: undefined,
        weight: 1,
        index_rule_id: -2,
        score_rule_id: -1,
        set_id: -1,
        enum_id: undefined,
        unit_id: undefined,
        decision_id: -1,
        min: undefined,
        max: undefined,
    };
    private newCategories: string[] = [];
    private blankOptions = {
        style_class: "vp-body-1",
        categories: this.newCategories,
    };
    private factor: Factor = { ...this.blankFactor };
    private factorOptions = { ...this.blankOptions };
    private isFormValid = false;
    private loading = false;
    private factorNameList = "";

    private factorEnum: Enum | null = null;
    private newEnums = "";
    private optionsList = "";
    private factorNamesArray: string[] = [];
    private categoryOptionsArray: string[] = [];
    private factorList: FactorCreateInputL[] = [];
    private enumIdList: number[] = [];
    // Factor Score Options
    private scoreOpen = false;
    private manualScore = true;

    private rules = {
        quote: (v: string) =>
            v == null || !v.includes('"') || "Please use single quotes",
        minLessThanMax: (min: number | string, max: number | string) => {
            //When input empty, max and min equal empty string which is essentially unbounded max/min
            if (max == null || min == null || [max, min].includes("")) {
                return true;
            }
            //If both inputs are non-empty and valid numbers then check if max is greater than min
            if (typeof max === "number" && typeof min === "number") {
                if (max - min > 0) {
                    return true;
                }
                return "Min must be less than Max";
            }
            return true;
        },
        negativeValuesValid: (
            valueType: string | undefined,
            min: number | string,
            max: number | string
        ) => {
            if (valueType === "string" && (min < 0 || max < 0)) {
                return "Text length can't be less than 0";
            }
            return true;
        },
    };

    private open = false;
    private selectedCategoryOptions = [];

    private processPdfTask: Task | null = null;
    private pdfId = "";

    private count = 0;
    private timeout: any = null;
    private firstPage = 1;
    private lastPage = 1;
    private pdfGroupParentId = -1;
    private fileUploaded = false;
    private factorsCreated = false;
    private factorUpdates: any = {};

    get editFactor(): boolean {
        if (this.editable) {
            return !this.addOnly;
        } else {
            return this.editable;
        }
    }

    get factorType(): string {
        if (this.factorEdit.is_group) {
            return "Group";
        } else if (this.factorEdit.is_table) {
            return "Table";
        } else if (this.factorEdit.is_column) {
            return "Column";
        } else {
            return this.factorLabelSingular;
        }
    }

    get decisionId(): number | null {
        return getModule(Decisions).selectedDecisionId;
    }

    get unitsList(): Unit[] {
        return getModule(Domains).unitsList;
    }

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get currentTreeLevel(): Factor[] | null {
        return this.factorEdit ? this.factorTree[this.factorEdit.id] : null;
    }

    get tableRows(): Factor[] {
        if (this.currentTreeLevel && this.currentTreeLevel[0]) {
            return this.factorTree[this.currentTreeLevel[0].id];
        } else {
            return [];
        }
    }

    get createBtnDisabled(): boolean {
        return (
            !this.isFormValid ||
            (!this.factor.is_group &&
                !this.factor.is_table &&
                this.factor.value_type == "") ||
            (this.factor.name === "" && !this.isPdf
                ? this.factorNameList === ""
                : this.isPdf) ||
            this.factor.weight === undefined ||
            this.factor.weight === null ||
            this.factor.weight < 0 ||
            !this.factor.score_rule_id ||
            !this.factor.index_rule_id
        );
    }

    get pdfCreateBtnDisabled(): boolean {
        return (
            !this.fileUploaded ||
            this.factor.value_type == "" ||
            this.firstPage < 0 ||
            this.lastPage < 0
        );
    }

    get defaultClassSets(): DefaultClassSets {
        const workspaceClassSetsList = scoringModule.classClusters;
        return _.chain(workspaceClassSetsList).groupBy("org_id").value();
    }

    get defaultFactorSetId(): number | undefined {
        if (this.defaultClassSets && this.defaultClassSets[-1]) {
            return this.defaultClassSets[-1][0].id;
        } else {
            return undefined;
        }
    }

    get defaultGroupSetId(): number | undefined {
        if (this.defaultClassSets && this.defaultClassSets[-2]) {
            return this.defaultClassSets[-2][0].id;
        } else {
            return undefined;
        }
    }

    get defaultCategorySetId(): number | undefined {
        if (this.defaultClassSets && this.defaultClassSets[-3]) {
            return this.defaultClassSets[-3][0].id;
        } else {
            return undefined;
        }
    }

    get defaultDateAndTimeSetId(): number | undefined {
        if (this.defaultClassSets && this.defaultClassSets[-4]) {
            return this.defaultClassSets[-4][0].id;
        } else {
            return undefined;
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get factorStyleClass(): any {
        if (this.factor.json) {
            return JSON.parse(this.factor.json).style_class
                ? JSON.parse(this.factor.json).style_class
                : "vp-body-1";
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set factorStyleClass(value: any) {
        this.factor.json = value.value;
    }

    mounted(): void {
        this.onFactorChange();
    }

    @Watch("factorEdit", { deep: true })
    private onFactorChange() {
        if (this.factorEdit != null) {
            this.factor = Object.assign({}, this.factorEdit);
            this.newEnums = "";
            if (this.factorEdit.enum_id) {
                this.factorEnum = {
                    ...modelModule.enums[this.factorEdit.enum_id],
                };
            } else {
                this.factorEnum = null;
            }
            if (this.factor.json) {
                try {
                    this.factorOptions = Object.assign(
                        this.blankOptions,
                        JSON.parse(this.factor.json)
                    );
                } catch (e) {
                    // Try / Catch block in case there is an issue with the JSON string loaded from Backend
                    this.factorOptions = { ...this.blankOptions };
                }
            } else {
                this.factorOptions = { ...this.blankOptions };
            }
        } else {
            this.factor = { ...this.blankFactor };
            this.factorOptions = { ...this.blankOptions };
            this.factorEnum = null;
        }
        this.factorUpdates = {};
    }

    @Watch("factorStyleClass")
    private onFactorClassChange() {
        //this.factorOptions = this.factorStyleClass;
    }

    get factors(): Factor[] {
        return modelModule.factors;
    }

    get factorTypes(): FactorType[] {
        return modelModule.factorTypeList;
    }

    get factorTypeIndex(): { [id: string]: string } {
        return this.valueTypes.reduce((acc, a) => {
            return {
                ...acc,
                [a.id]: a.text,
            };
        }, {});
    }

    get factorTypesDict(): { [id: number]: FactorType } {
        return modelModule.factorTypes;
    }

    get valueTypes(): { text: string; id: string }[] {
        if (this.isColumn || this.isRow || this.factorEdit != null) {
            return modelModule.valueTypes.filter(
                (type) => !["table", "group"].includes(type.id)
            );
        }
        return modelModule.valueTypes.filter(
            (type) => !["table"].includes(type.id)
        );
    }

    get factorLabelSingular(): string {
        return getModule(Decisions).factorLabelSingular;
    }

    get factorLabelPlural(): string {
        return getModule(Decisions).factorLabelPlural;
    }

    get formatDict(): {
        string: {
            values: string[];
        };
    } {
        return modelModule.formatDict;
    }

    get rootFactorId(): number {
        return modelModule.factorRoot?.id ?? -1;
    }

    private async createFactor() {
        this.loading = true;
        if (this.factorEdit != null) {
            //Edit Factor
        }

        if (this.factorNameList) {
            const listArray: string[] = this.factorNameList.split(/\r?\n/);
            this.factorNamesArray = listArray
                .map((inputString: string) => inputString.trim())
                .filter(
                    (inputString: string) => ![null, ""].includes(inputString)
                );

            const order_strs = getNextOrderStrs(
                this.factorTree[this.parentId],
                this.factorNamesArray.length
            );

            if (this.optionsList) {
                const listOptionsArray: string[] =
                    this.optionsList.split(/\r?\n/);
                this.categoryOptionsArray = listOptionsArray
                    .map((inputOptionsString: string) =>
                        inputOptionsString.trim()
                    )
                    .filter(
                        (inputOptionsString: string) =>
                            ![null, ""].includes(inputOptionsString)
                    );
            }

            if (
                this.factor.value_type == "category" &&
                !this.factorEnum &&
                this.factor.enum_id == null
            ) {
                await Promise.all(
                    this.factorNamesArray.map(async (item: string) => {
                        let factorEnums;
                        let enum_id;
                        if (this.decisionId) {
                            factorEnums = await modelModule.createEnums({
                                decision_id: this.decisionId,
                                names: [item],
                            });
                        }
                        if (factorEnums) {
                            factorEnums.map(async (item) => {
                                enum_id = item.id ? item.id : undefined;

                                if (enum_id) {
                                    this.enumIdList.push(enum_id);
                                }

                                if (
                                    enum_id != undefined &&
                                    this.categoryOptionsArray.length > 0
                                )
                                    await modelModule.createEnumValues({
                                        enum_id: enum_id,
                                        values: this.categoryOptionsArray,
                                    });
                            });
                        }
                    })
                );
            }
            if (
                this.factor.score_rule_id &&
                this.factor.weight &&
                this.factor.index_rule_id &&
                this.factor.set_id
            ) {
                createFactorL({
                    names: this.factorNamesArray,
                    parentId: this.parentId ? this.parentId : this.rootFactorId,
                    valueType: this.factor.value_type,
                    description: this.factor.description,
                    order_str: order_strs,
                    options: JSON.stringify(this.factorOptions),
                    unit_id: this.factor.unit_id,
                    format: this.factor.format,
                    isColumn: this.isColumn,
                    useManualScore: this.factor.m_score,
                    useManualIndex: this.factor.m_index,
                    enum_ids: this.enumIdList ? this.enumIdList : undefined,
                    weight: this.factor.weight,
                    min: this.factor.min,
                    max: this.factor.max,
                    score_rule_id: this.factor.score_rule_id,
                    index_rule_id: this.factor.index_rule_id,
                    set_id: this.factor.set_id,
                });
            } else {
                flashNotificationsModule.error({
                    message: `Unexpected error, some of the mandatory fields are empty`,
                    duration: 5000,
                });
                console.error(
                    "score_rule_id - ",
                    this.factor.score_rule_id,
                    "weight - ",
                    this.factor.weight,
                    "index_rule_id - ",
                    this.factor.index_rule_id,
                    "set_id- ",
                    this.factor.set_id
                );
            }
            // clean local list with enum ids after Factor was created;
            this.enumIdList = [];
        }

        this.factor = { ...this.blankFactor };
        this.factorOptions = { ...this.blankOptions };
        this.factorNameList = "";
        this.optionsList = "";
        this.factorNamesArray = [];
        this.categoryOptionsArray = [];
        this.loading = false;
    }

    private async saveFactor() {
        if (this.newEnums.length > 0) {
            setTimeout(() => {
                const addOptionsBtn = this.$refs.addOptionsBtn as any;
                if (addOptionsBtn) addOptionsBtn.$el.focus();
            });
            return;
        }
        this.loading = true;
        await updateFactorL({
            factor: this.factor,
            parentId: this.factorUpdates.parentId,
            name: this.factorUpdates.name,
            // valueType: this.factor.value_type,
            description: this.factorUpdates.description,
            options: JSON.stringify(this.factorUpdates.factorOptions),
            format: this.factorUpdates.format,
            m_score: this.factorUpdates.m_score,
            m_index: this.factorUpdates.m_index,
            weight: this.factorUpdates.weight,
            unit_id: this.factorUpdates.unit_id,
            score_rule_id: this.factorUpdates.score_rule_id,
            index_rule_id: this.factorUpdates.index_rule_id,
            set_id: this.factorUpdates.set_id,
            max: this.factor.max,
            min: this.factor.min,
        });
        this.loading = false;
    }

    private onChangeFactorValueType() {
        this.factor.format = "";
        let decisionDefaults = decisionModule.allDecisionDefault;
        this.setManualScore();
        console.log(decisionDefaults);
        console.log(Object.keys(decisionDefaults).length > 0);

        if (decisionDefaults && Object.keys(decisionDefaults).length > 0) {
            this.setDecisionDefaultRules();
        } else {
            this.setDefaultRule();
        }

        if (this.factor.value_type == "group") {
            this.factor.is_group = true;
        } else {
            this.factor.is_group = false;
        }

        if (this.factor.value_type === "table") {
            this.factor.is_table = true;
        } else {
            this.factor.is_table = false;
        }

        if (["string", "number"].includes(this.factor.value_type)) {
            this.factor.min = 0;
            this.factor.max = undefined;
        } else {
            this.factor.min = undefined;
            this.factor.max = undefined;
        }
    }

    private async addCategoryOptions(): Promise<void> {
        this.loading = true;

        if (!this.factorEnum) {
            if (this.decisionId) {
                let factorEnums = await modelModule.createEnums({
                    decision_id: this.decisionId,
                    names: [this.factor.name],
                });
                this.factorEnum = factorEnums ? factorEnums[0] : null;
            }
        }

        const listOptionsArray: string[] = this.newEnums.split(/\r?\n/);
        this.categoryOptionsArray = listOptionsArray
            .map((inputOptionsString: string) => inputOptionsString.trim())
            .filter(
                (inputOptionsString: string) =>
                    ![null, ""].includes(inputOptionsString)
            );

        if (this.factorEnum && this.categoryOptionsArray.length > 0) {
            await modelModule.createEnumValues({
                enum_id: this.factorEnum.id,
                values: this.categoryOptionsArray,
            });
        }
        flashNotificationsModule.success({
            message: `Saved`,
            duration: 1000,
        });
        Vue.set(this.factor, "enum_id", this.factorEnum?.id);
        this.newEnums = "";
        this.categoryOptionsArray = [];
        this.loading = false;
    }

    private async removeCategoryOptions(): Promise<void> {
        this.loading = true;
        if (this.factorEdit.enum_id && this.selectedCategoryOptions.length > 0)
            await modelModule.deleteEnumValues({
                enum_id: this.factorEdit.enum_id,
                enum_value_ids: this.selectedCategoryOptions,
            });
        this.selectedCategoryOptions = [];
        this.loading = false;
    }

    private async updateCategoryOption(
        value: string,
        id: number
    ): Promise<void> {
        this.loading = true;

        await modelModule.updateEnumValue({
            value: value,
            enum_value_id: id,
        });
        console.log(`Value: ${value} updated`);
        this.loading = false;
    }

    private setWeight(val: number): void {
        this.factor.weight = val;
    }

    private setRule(val: number): void {
        this.factor.score_rule_id = val;
    }

    private setClassRule(val: number): void {
        this.factor.index_rule_id = val;
    }

    // private setManualIndex(val: boolean): void {
    //     this.factor.m_index = val;
    // }

    // private setManualScore(val: boolean): void {
    //     this.factor.m_score = val;
    // }

    private setClassCluster(val: number): void {
        this.factor.set_id = val;
    }

    private async updateTextDescription(descriptionValue: string) {
        this.factor.description = descriptionValue;
        this.factorUpdates.description = descriptionValue;
    }

    private setRules(payload: {
        class_rule_id: number;
        score_rule_id: number;
        set_id: number;
    }) {
        this.factor.score_rule_id = payload.score_rule_id;
        this.factor.index_rule_id = payload.class_rule_id;
        if (payload.set_id) {
            this.factor.set_id = payload.set_id;
        }
    }

    private setManualScore() {
        const falseTypes = [
            "group",
            "number",
            "date_time",
            "table",
            "category",
        ];
        if (falseTypes.includes(this.factor.value_type)) {
            this.factor.m_score = undefined;
            this.factor.m_index = undefined;
        } else {
            this.factor.m_score = 0;
            this.factor.m_index = undefined;
        }
    }

    private setDefaultRule() {
        // we are using rule ids from decision with id:-2;
        const orgIdToDefaultRuleMap = scoringModule.defaultRules;
        switch (this.factor.value_type) {
            case "number":
                this.factor.index_rule_id = orgIdToDefaultRuleMap[-6].id;
                this.factor.score_rule_id = orgIdToDefaultRuleMap[-5].id;
                this.factor.set_id = this.defaultGroupSetId;
                break;
            case "date_time":
                this.factor.index_rule_id = orgIdToDefaultRuleMap[-10].id;
                this.factor.score_rule_id = orgIdToDefaultRuleMap[-7].id;
                this.factor.set_id = this.defaultDateAndTimeSetId;
                break;
            case "category":
                this.factor.index_rule_id = orgIdToDefaultRuleMap[-4].id;
                this.factor.score_rule_id = orgIdToDefaultRuleMap[-3].id;
                this.factor.set_id = this.defaultCategorySetId;
                break;
            case "group":
                this.factor.index_rule_id = orgIdToDefaultRuleMap[-9].id;
                this.factor.score_rule_id = orgIdToDefaultRuleMap[-8].id;
                this.factor.set_id = this.defaultGroupSetId;
                break;
            case "table":
                this.factor.index_rule_id = orgIdToDefaultRuleMap[-9].id;
                this.factor.score_rule_id = orgIdToDefaultRuleMap[-8].id;
                this.factor.set_id = this.defaultGroupSetId;
                break;
            default:
                this.factor.index_rule_id = orgIdToDefaultRuleMap[-2].id;
                this.factor.score_rule_id = orgIdToDefaultRuleMap[-1].id;
                this.factor.set_id = this.defaultFactorSetId;
        }
    }

    private setDecisionDefaultRules() {
        const decisionDefaults: DecisionDefaults =
            decisionModule.allDecisionDefault;

        console.log(decisionDefaults);
        console.log(this.factor.value_type);

        switch (this.factor.value_type) {
            case "number":
                this.factor.index_rule_id =
                    decisionDefaults[this.factor.value_type].index_rule_id;
                this.factor.score_rule_id =
                    decisionDefaults[this.factor.value_type].score_rule_id;
                this.factor.set_id =
                    decisionDefaults[this.factor.value_type].set_id;
                break;
            case "date_time":
                this.factor.index_rule_id =
                    decisionDefaults[this.factor.value_type].index_rule_id;
                this.factor.score_rule_id =
                    decisionDefaults[this.factor.value_type].score_rule_id;
                this.factor.set_id =
                    decisionDefaults[this.factor.value_type].set_id;
                break;
            case "category":
                this.factor.index_rule_id =
                    decisionDefaults[this.factor.value_type].index_rule_id;
                this.factor.score_rule_id =
                    decisionDefaults[this.factor.value_type].score_rule_id;
                this.factor.set_id =
                    decisionDefaults[this.factor.value_type].set_id;
                break;
            case "group":
                this.factor.index_rule_id =
                    decisionDefaults[this.factor.value_type].index_rule_id;
                this.factor.score_rule_id =
                    decisionDefaults[this.factor.value_type].score_rule_id;
                this.factor.set_id =
                    decisionDefaults[this.factor.value_type].set_id;
                break;
            case "table":
                this.factor.index_rule_id =
                    decisionDefaults[this.factor.value_type].index_rule_id;
                this.factor.score_rule_id =
                    decisionDefaults[this.factor.value_type].score_rule_id;
                this.factor.set_id =
                    decisionDefaults[this.factor.value_type].set_id;
                break;
            default:
                this.factor.index_rule_id =
                    decisionDefaults["default"].index_rule_id;
                this.factor.score_rule_id =
                    decisionDefaults["default"].score_rule_id;
                this.factor.set_id = decisionDefaults["default"].set_id;
        }
    }

    private async processFactorFromPdf() {
        this.loading = true;
        if (this.pdfId) {
            try {
                let processResult = await modelModule.processPdf({
                    pdf_id: this.pdfId,
                    first_page: this.firstPage,
                    last_page: this.lastPage,
                });
                this.processPdfTask = processResult;
                this.getTitles();
            } catch (error) {
                flashNotificationsModule.error({
                    message: `Unexpected error, system cannot read this PDF`,
                    duration: 5000,
                });
                this.loading = false;
            }
        } else {
            this.loading = false;
        }
    }

    private async getTitles() {
        if (this.processPdfTask) {
            try {
                let results = await modelModule.getPdfResults(
                    this.processPdfTask.id
                );
                if (results) {
                    let taskStatus = results.task.status;

                    if (taskStatus == "PENDING" && this.count <= 6) {
                        this.count++;

                        if (this.timeout) clearTimeout(this.timeout);
                        this.timeout = setTimeout(() => {
                            this.getTitles();
                        }, 5000);
                    } else if (taskStatus == "FAILURE") {
                        this.loading = false;
                        flashNotificationsModule.error({
                            message: `Unexpected error, system cannot read this PDF`,
                            duration: 5000,
                        });
                    } else if (taskStatus == "SUCCESS") {
                        let titleList = results.result;

                        // Grouping all titles by parent_id
                        let groupedTitles = _.chain(titleList)
                            .groupBy("parent_id")
                            .map((value, key) => ({
                                group: key,
                                factors: value,
                                titles: value.map((title) => title.name),
                            }))
                            .value();
                        // get an object with titles for parent groups
                        let groupTitles = _.find(groupedTitles, [
                            "group",
                            "null",
                        ]);
                        // check child titles
                        groupedTitles.forEach(async (groupedTitle) => {
                            if (
                                groupedTitle.group != "null" &&
                                groupedTitles.length > 1
                            ) {
                                let titles = groupedTitle.titles;
                                let groupTitle = groupTitles
                                    ? _.find(groupTitles.factors, [
                                          "id",
                                          Number(groupedTitle.group),
                                      ])
                                    : null;
                                if (groupTitle) {
                                    this.factorNamesArray = [groupTitle.name];
                                    const order_strs = getNextOrderStrs(
                                        this.factorTree[this.parentId],
                                        this.factorNamesArray.length
                                    );
                                    let parentFactorGroup =
                                        await modelModule.createGroupL({
                                            parentId: this.parentId,
                                            names: [groupTitle.name],
                                            description: "",
                                            ordering: order_strs,
                                            json: JSON.stringify(
                                                this.factorOptions
                                            ),
                                            weight: this.factor.weight,
                                            score_rule_id: 1724,
                                            index_rule_id: 1725,
                                            set_id: -1,
                                        });
                                    if (parentFactorGroup) {
                                        // asigne child factors names
                                        this.factorNamesArray = titles;
                                        this.pdfGroupParentId =
                                            parentFactorGroup[0].id;
                                        await this.createFactorsFromPdf();
                                    }
                                }
                            } else if (
                                groupedTitle.group == "null" &&
                                groupedTitles.length == 1
                            ) {
                                let titles = titleList.map(
                                    (title) => title.name
                                );

                                const order_strs = getNextOrderStrs(
                                    this.factorTree[this.parentId],
                                    this.factorNamesArray.length
                                );
                                await modelModule.createGroupL({
                                    parentId: this.parentId,
                                    names: titles,
                                    description: "",
                                    ordering: order_strs,
                                    json: JSON.stringify(this.factorOptions),
                                    weight: this.factor.weight,
                                    score_rule_id: 1724,
                                    index_rule_id: 1725,
                                    set_id: -1,
                                });
                            }
                        });
                        this.fileUploaded = false;
                        this.factorsCreated = true;
                        this.firstPage = 1;
                        this.lastPage = 1;
                    } else {
                        this.loading = false;
                        this.count = 0;
                        if (this.timeout) clearTimeout(this.timeout);
                        flashNotificationsModule.warning({
                            message: `It looks like the process is taking too long. Try one more time or use a smaller range for pages.`,
                            duration: 10000,
                        });
                    }
                }
            } catch (error) {
                flashNotificationsModule.error({
                    message: `Unexpected error, system cannot read this file. Try one more time or use a smaller range for pages.`,
                    duration: 10000,
                });
                this.factorsCreated = false;
                this.count = 0;
                if (this.timeout) clearTimeout(this.timeout);
                this.loading = false;
            }
        }
    }

    private async createFactorsFromPdf() {
        if (this.factorNamesArray.length > 0) {
            const order_strs = getNextOrderStrs(
                this.factorTree[this.pdfGroupParentId],
                this.factorNamesArray.length
            );
            let enumIdList: number[] = [];
            if (
                this.factor.score_rule_id &&
                this.factor.weight &&
                this.factor.index_rule_id &&
                this.factor.set_id
            ) {
                if (
                    this.factor.value_type == "category" &&
                    !this.factorEnum &&
                    this.factor.enum_id == null
                ) {
                    if (this.optionsList) {
                        const listOptionsArray: string[] =
                            this.optionsList.split(/\r?\n/);
                        this.categoryOptionsArray = listOptionsArray
                            .map((inputOptionsString: string) =>
                                inputOptionsString.trim()
                            )
                            .filter(
                                (inputOptionsString: string) =>
                                    ![null, ""].includes(inputOptionsString)
                            );
                    }
                    await Promise.all(
                        this.factorNamesArray.map(async (item: string) => {
                            let factorEnums;
                            let enum_id;
                            if (this.decisionId) {
                                factorEnums = await modelModule.createEnums({
                                    decision_id: this.decisionId,
                                    names: [item],
                                });
                            }
                            if (factorEnums) {
                                factorEnums.map(async (item) => {
                                    enum_id = item.id ? item.id : undefined;

                                    if (enum_id) {
                                        enumIdList.push(enum_id);
                                    }

                                    if (
                                        enum_id != undefined &&
                                        this.categoryOptionsArray.length > 0
                                    )
                                        await modelModule.createEnumValues({
                                            enum_id: enum_id,
                                            values: this.categoryOptionsArray,
                                        });
                                });
                            }
                        })
                    );
                }
                createFactorL({
                    names: this.factorNamesArray,
                    parentId: this.pdfGroupParentId,
                    valueType: this.factor.value_type,
                    description: this.factor.description,
                    order_str: order_strs,
                    options: JSON.stringify(this.factorOptions),
                    unit_id: this.factor.unit_id,
                    format: this.factor.format,
                    isColumn: this.isColumn,
                    useManualScore: this.factor.m_score,
                    useManualIndex: this.factor.m_index,
                    enum_ids: enumIdList.length > 0 ? enumIdList : undefined,
                    weight: this.factor.weight,
                });
            } else {
                flashNotificationsModule.error({
                    message: `Unexpected error, some of the mandatory fields are empty`,
                    duration: 5000,
                });
                console.error(
                    "score_rule_id - ",
                    this.factor.score_rule_id,
                    "weight - ",
                    this.factor.weight,
                    "index_rule_id - ",
                    this.factor.index_rule_id,
                    "set_id- ",
                    this.factor.set_id
                );
            }
            this.loading = false;
        } else {
            this.loading = false;
            this.factorsCreated = false;
            flashNotificationsModule.error({
                message: `Unexpected error, factorNamesArray is empty`,
                duration: 5000,
            });
        }
    }

    private setPdfId(id: string) {
        this.fileUploaded = true;
        this.pdfId = id;
    }

    private setLastPage(last: number) {
        this.fileUploaded = false;
        this.lastPage = last;
    }

    private updateFactorName(name: string) {
        this.factorUpdates.name = name;
    }

    private updateFactorTextFormat(format: string) {
        this.factorUpdates.format = format;
    }

    private updateFactorOptions() {
        this.factorUpdates.factorOptions = this.factorOptions;
    }

    @Watch("parentId")
    private onFactorParentIdChange() {
        if (this.parentId) this.factorUpdates.parentId = this.parentId;
    }
}
