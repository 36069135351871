
import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Factor, Choice, Tab } from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import Factors from "@/store/modules/Factors";
import Choices from "@/store/modules/Choices";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import CommentBase from "@/components/mixins/CommentBase";
import CommentObjects from "@/components/comments/tab/CommentObjects.vue";
import CommentSettings from "@/components/comments/tab/CommentSettings.vue";
import ComparePagination from "@/components/compare/ui/ComparePagination.vue";
import CompareColumnHeaders from "@/components/compare/ui/CompareColumnHeaders.vue";
import CommentRecursive from "@/components/comments/tab/CommentRecursive.vue";
import CommentSideBar from "@/components/comments/tab/panel/CommentSideBar.vue";
import CommentChoices from "@/components/comments/tab/CommentChoices.vue";
import ViewAppCreator from "@/components/apps/ViewAppCreator.vue";
import { saveTab } from "@/helpers/TabHelper";

const decisionsModule = getModule(Decisions);
const modelModule = getModule(Factors);
const choiceModule = getModule(Choices);

@Component({
    components: {
        ViewWrapper,
        CommentObjects,
        CommentSettings,
        ComparePagination,
        CompareColumnHeaders,
        CommentRecursive,
        CommentSideBar,
        CommentChoices,
        ViewAppCreator,
    },
})
export default class CommentsView extends mixins(CommentBase) {
    private darkBorder = true;

    private selectedFactorId: number | null = null;
    private selectedChoiceId: number | null = null;

    private appCreateOpen = false;

    get pageTitle(): string {
        return "Comments | Choices";
    }

    get choiceLabelPlural(): string {
        return decisionsModule.choiceLabelPlural;
    }

    get choicesLabel(): string {
        return decisionsModule.choiceLabelPlural;
    }

    get choiceLabel(): string {
        return decisionsModule.choiceLabelSingular;
    }

    get columnPages(): number {
        if (!this.pagination.columns.all && this.pagination.columns.items > 0) {
            return this.compareChoices.length - this.pagination.columns.items;
        } else {
            return 0;
        }
    }

    get selectedFactor(): Factor | null {
        if (
            this.selectedFactorId &&
            modelModule.factorMap[this.selectedFactorId]
        ) {
            return modelModule.factorMap[this.selectedFactorId];
        } else {
            return null;
        }
    }

    get selectedChoice(): Choice | null {
        if (
            this.selectedChoiceId &&
            choiceModule.choiceList[this.selectedChoiceId]
        ) {
            return choiceModule.choiceList[this.selectedChoiceId];
        } else {
            return null;
        }
    }

    /* Tab Settings */
    get currentUiOptions(): string {
        return JSON.stringify({
            mainColumn: this.mainColumnWidth,
            dataColumn: this.dataColumnWidth,
            paddingX: this.paddingX,
            paddingY: this.paddingY,
            borderX: this.borderX,
            borderY: this.borderY,
            pagination: this.pagination,
            fullSingleCol: this.fullSingleCol,
            showTotal: this.showTotal,
        });
    }

    get currentSettings(): Tab {
        if (this.tabSettings) {
            return {
                ...this.tabSettings,
                json: this.currentUiOptions,
            };
        } else {
            return {
                id: -1,
                decision_id: this.decisionId ? this.decisionId : -1,
                title: "Comments",
                json: this.currentUiOptions,
                edit_flags: "",
                display_flags: "",
                filter_type: "viewpoint",
                row_type: "factor",
                column_type: "choice",
                type: "CommentsView",
            };
        }
    }

    /* Compare Values can be sorted here */
    get compareChoices(): Choice[] {
        return this.choices;
    }

    /* Pagination */
    get paginationChoices(): Choice[] {
        if (!this.pagination.columns.all) {
            return this.compareChoices.slice(
                this.pagination.columns.position,
                this.pagination.columns.position + this.pagination.columns.items
            );
        } else {
            return this.compareChoices;
        }
    }

    async mounted(): Promise<void> {
        this.onChoiceChange(this.paginationChoices, []);
        this.loadingTabSettings = true;
        await this.loadTabOptions();
        this.loadingTabSettings = false;
    }

    private toggleComments(payload: { factor: Factor; choice: Choice }) {
        this.selectedFactorId = payload.factor.id;
        this.selectedChoiceId = payload.choice.id;
        this.sidebarIndex = "comments";
        this.settingsMode = "comments";
    }

    private async loadChoiceValues(choices: Choice[]): Promise<void> {
        if (choices.length) {
            await Promise.all(
                choices.map(async (choice) => {
                    await choiceModule.getChoiceValues({
                        choice_id: choice.id,
                        root_only: true,
                    });
                })
            );
        }
    }

    private async saveSettings(): Promise<void> {
        if (this.tabSettings && this.isEdit) {
            this.loading = true;
            await saveTab({
                tab: {
                    ...this.currentSettings,
                },
                new: false,
                factors: [],
                choices: this.selectedChoices,
                viewpoints: [],
            })
            this.loading = false;
        } else {
            this.appCreateOpen = true;
        }
    }

    @Watch("paginationChoices", { immediate: true, deep: true })
    async onChoiceChange(
        newVal: Choice[],
        oldVal: Choice[] = []
    ): Promise<void> {
        if (newVal && newVal.length) {
            this.loadChoiceValues(newVal.filter((x) => !oldVal.includes(x)));
        }
    }

}
